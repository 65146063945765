<template>
  <div style="margin-top: 30px" class="d-flex min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol xs="12" lg="6">
          <CCard>
            <CCardHeader>
              Edit Profile
            </CCardHeader>
            <CCardBody>
              <CTabs>
                <CTab title="My Details" active>
                  <CCardBody class="p-4">
                    <CForm>
                      <h1 style="text-align: center">My Details</h1>

                      <CInput
                        :disabled="!editing"
                        placeholder="Your Name"
                        autocomplete="name"
                        v-model="fd.name"
                        :value="user.name"
                      >
                        <template #prepend-content
                          ><CIcon name="cil-user"
                        /></template>
                      </CInput>

                      <CInput
                        :disabled="!editing"
                        placeholder="Email"
                        autocomplete="email"
                        prepend="@"
                        v-model="fd.email"
                        :value="user.initials"
                      />
                      <CInput
                        disabled
                        placeholder="Initials"
                        prepend="AB"
                        v-model="fd.initials"
                        :value="user.initials"
                      />

                      <CButton
                        class="mr-2"
                        v-show="editing === false"
                        color="primary"
                        @click="editing = !editing"
                      >
                        Click to Edit
                      </CButton>
                      <CButton
                        v-show="editing === true"
                        class="mr-2"
                        @click="save_profile"
                        color="success"
                        >Save</CButton
                      >
                      <CButton
                        v-show="editing === true"
                        class="mr-2"
                        @click="editing = !editing"
                        color="light"
                        >Cancel</CButton
                      >
                    </CForm>
                  </CCardBody>
                </CTab>
                <CTab title="Password">
                  <CCardBody class="p-4">
                    <CForm>
                      <h1 style="text-align: center">Change Password</h1>

                      <CInput
                        placeholder="Old Password"
                        v-model="pswd_form.old_password"
                        type="password"
                      >
                      </CInput>
                      <hr />

                      <CInput
                        placeholder="New Password"
                        v-model="pswd_form.password"
                        type="password"
                      />
                      <CInput
                        placeholder="Confirm New Password"
                        v-model="pswd_form.password_confirmation"
                        type="password"
                      />

                      <CButton
                        class="mr-2"
                        @click="save_password"
                        block
                        color="primary"
                      >
                        Update Password
                      </CButton>
                    </CForm>
                  </CCardBody>
                </CTab>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Profile",
  data() {
    return {
      editing: false,
      user: {},
      fd: {
        name: "",
        email: "",
        initials: "",
        id: "",
      },
      pswd_form: {
        id: "",
        old_password: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  mounted() {
    var user = JSON.parse(localStorage.getItem("user"));
    this.user = user;
    this.fd = user;
    this.pswd_form.id = user.id;
  },
  methods: {
    save_profile() {
      var vm = this;
      vm.editing = !vm.editing;
      console.log(vm.fd);
      axios
        .post("/user/update", vm.fd)
        .then(function(response) {
          console.log(response);
          vm.user = response.data;
          localStorage.setItem("user", JSON.stringify(response.data));
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Saved!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function(error) {
          console.log(error);
          vm.editing = !vm.editing;
          Swal.fire("Oops...", "Something went wrong!", "error");
        });
    },
    save_password() {
      var vm = this;
      // vm.editing = !vm.editing;
      console.log(vm.pswd_form);
      axios
        .post("/user/update/pswd", vm.pswd_form)
        .then(function(response) {
          console.log(response);
          vm.user = response.data;
          localStorage.setItem("user", JSON.stringify(response.data));
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Password Changed!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function(error) {
          console.log(error);
          // vm.editing = !vm.editing;
          Swal.fire("Oops...", error.message, "error");
        });
    },
  },
};
</script>
